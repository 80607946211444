class FixedBody {
    constructor() {

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }

    /**
     * @description Bodyのスクロールを無効にする
     */
    fixedBody() {
        const body = document.body
        body.style.position = "fixed"
    }

    /**
     * @description Bodyのスクロールを有効にする
     */
    unFixedBody() {
        const body = document.body
        body.style.position = ''
    }

}

export default new FixedBody()
