import FixedBody from "../utils/fixedBody";
import { formatDate } from "../utils/format_date";
import "./changeTabsAreaModal";

$(document).ready(function () {
  const DEFAULT_TIMES = ["07", "00", "08", "00"];
  const DEFAULT_DATE = "";
  const API = "/rentalgym";

  const searchForm = document.getElementById("form-search");
  const modals = document.querySelectorAll(".modal");
  const areaSearch = document.getElementById("area-search");
  const equipmentSearch = document.getElementById("equipment-search");
  const timeSearch = document.getElementById("time-search");
  const areaSearchModal = document.getElementById("area-search-modal");
  const equipmentSearchModal = document.getElementById(
    "equipment-search-modal"
  );
  const timeSearchModal = document.getElementById("time-search-modal");

  const equipmentCloseClass = "close-modal-equipment";
  const timeCloseClass = "close-modal-time";
  const areaCloseClass = "close-modal-area";

  let areaValues = [];
  let equipmentValues = [];
  let timeValues = [];
  let isClearDate = false;
  let started_at = "";
  let ended_at = "";
  let areas = [];
  let equipments = [];
  let areaKeyword = "";
  disableAreaSearch();
  setArea();
  getAreaQuery();
  setEquipment();
  getEquipmentQuery();
  setTime();
  getTimeQuery();
  areaSearch &&
    areaSearch.addEventListener("click", function () {
      areaSearchModal.style.display = "block";
      disableAreaSearch();
      clearAll("area");
      filterArea();
      closeSearchModal(areaCloseClass);
    });

  equipmentSearch &&
    equipmentSearch.addEventListener("click", function () {
      equipmentSearchModal.style.display = "block";
      clearAll("equipment");
      filterEquipment();
      closeSearchModal(equipmentCloseClass);
    });

  timeSearch &&
    timeSearch.addEventListener("click", function () {
      timeSearchModal.style.display = "block";
      clearAll("time");
      filterTime();
      closeSearchModal(timeCloseClass);
    });

  function closeSearchModal(className) {
    const closeModalButton = document.getElementById(className);
    FixedBody.fixedBody();
    closeModalButton.addEventListener("click", function () {
      modals.forEach((el) => {
        el.style.display = "none";
      });
      FixedBody.unFixedBody();
    });
    overlayClickCloseModal();
  }

  function overlayClickCloseModal() {
    const overlay = document.querySelectorAll(".modal-overlay");
    overlay.forEach((v) => {
      v.addEventListener("click", function (e) {
        if (e.target.closest(".modal-wrapper")) return;
        modals.forEach((el) => {
          el.style.display = "none";
        });
        FixedBody.unFixedBody();
      });
    });
  }

  function setArea() {
    const areaTexts = $("#area-content input[type=text]").toArray();
    areaTexts.forEach((el) => {
      if (el.value) {
        areaValues.push(el.value);
      }
    })
    const areaCheckBoxs = $("#area-content .area-form-control input[type=checkbox]").toArray()
    areaCheckBoxs.forEach((el) => {
      if (el.type === "checkbox" && el.checked) {
        areaValues.push(el.dataset.name);
      }
    })
    const areaSearchText = document.getElementById("area-search-text");
    if (areaValues.length > 0) {
      areaSearchText.innerText = searchText(areaValues);
    } else {
      areaSearchText.innerText = defaultSearchText("area");
    }
    areaValues = [];
  }

  function setEquipment() {
    const formContent = document.getElementById("equipment-content");
    const inputs = Array.from(formContent.getElementsByTagName("input"));
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type === "text" && inputs[i].value) {
        equipmentValues.push(inputs[i].value);
      }
      if (inputs[i].type === "checkbox" && inputs[i].checked) {
        equipmentValues.push(inputs[i].dataset.body);
      }
    }
    const equipmentSearchText = document.getElementById(
      "equipment-search-text"
    );

    if (equipmentValues.length > 0) {
      equipmentSearchText.innerText = searchText(equipmentValues);
    } else {
      equipmentSearchText.innerText = defaultSearchText("equipment");
    }
    equipmentValues = [];
  }

  function setTime() {
    const formContent = document.getElementById("time-content");
    let inputs = Array.from(formContent.getElementsByTagName("input"));
    const selects = Array.from(formContent.getElementsByTagName("select"));
    for (let i = 0; i < inputs.length; i++) {
      if (
        (inputs[i].type === "text" || inputs[i].type === "date") &&
        inputs[i].value
      ) {
        timeValues.push(inputs[i].value);
      }
      if (inputs[i].type === "checkbox" && inputs[i].checked) {
        timeValues.push(inputs[i].value);
      }
    }
    const timeSearchText = document.getElementById("time-search-text");
    if (isClearDate || timeValues.length === 0) {
      timeSearchText.innerText = defaultSearchText("time");
    } else {
      const date = new Date(timeValues[0]);
      timeSearchText.innerText = searchText([
        `${date.getMonth() + 1}月${date.getDate()}日`,
      ]);
    }

    timeValues = [];
  }

  // filter Area
  function filterArea() {
    const areaFilterBtns = document.querySelectorAll(".filter-button-area");
    areaFilterBtns.forEach((ev) => {
      ev.addEventListener("click", function (e) {
        e.preventDefault();
        setArea();
        modals.forEach((el) => {
          el.style.display = "none";
        });
        FixedBody.unFixedBody();
        getAreaQuery();
      });
    });
  }

  // filter Equipment
  function filterEquipment() {
    const equipmentFilterBtns = document.querySelectorAll(
      ".filter-button-equipment"
    );
    equipmentFilterBtns.forEach((ev) => {
      ev.addEventListener("click", function (e) {
        e.preventDefault();
        setEquipment();
        modals.forEach((el) => {
          el.style.display = "none";
        });
        FixedBody.unFixedBody();
        getEquipmentQuery();
      });
    });
  }

  // filter Time
  function filterTime() {
    const timeFilterBtns = document.querySelectorAll(".filter-button-time");
    timeFilterBtns.forEach((ev) => {
      ev.addEventListener("click", function (e) {
        e.preventDefault();
        setTime();
        //
        modals.forEach((el) => {
          el.style.display = "none";
        });
        FixedBody.unFixedBody();

        getTimeQuery();
      });
    });
    isClearDate = false;
  }

  // Get area query input
  function getAreaQuery() {
    areas = [];
    removeAllHiddenInputByNameInform("area_word");
    removeAllHiddenInputByNameInform("area_ids[]");

    const keyword = document.getElementById("area-search-keyword");

    const areaCheckBoxs = $("#area-content .area-form-control input[type=checkbox]").toArray()
    areaKeyword = keyword.value;
    if (areaKeyword) {
      addHiddenInputIntoForm("area_word", areaKeyword);
    }

    areaCheckBoxs.forEach((el) => {
      if (el.checked) {
        areas.push({
          uri: el.dataset.uri,
          id: el.value,
        });

        addHiddenInputIntoForm("area_ids[]", el.value);
      }
    });

    if (areas.length === 1) {
      removeAllHiddenInputByNameInform("area_ids[]");
    }
  }

  // Get Equipment query input
  function getEquipmentQuery() {
    equipments = [];
    removeAllHiddenInputByNameInform("equipment_strong_point_ids[]");

    const formContent = document.getElementById(`equipment-content`);
    const equipmentCheckBoxs = Array.from(
      formContent.getElementsByTagName("input")
    );

    equipmentCheckBoxs.forEach((el) => {
      if (el.type === "checkbox" && el.checked) {
        equipments.push(el.value);
        addHiddenInputIntoForm("equipment_strong_point_ids[]", el.value);
      }
    });
  }

  // Get Time query input
  function getTimeQuery() {
    removeAllHiddenInputByNameInform("started_at");
    removeAllHiddenInputByNameInform("ended_at");
    if (isClearDate) return;
    const dateSelected = document.getElementById("date-of-use").value;
    const formContent = document.getElementById(`time-content`);
    const timeSelects = Array.from(formContent.getElementsByTagName("select"));
    const times = [];

    timeSelects.forEach((el) => {
      times.push(el.value);
    });
    if (dateSelected) {
      started_at = `${formatDate(new Date(dateSelected), "yyyy-MM-dd")}T${
        times[0]
      }:${times[1]}`;
      ended_at = `${formatDate(new Date(dateSelected), "yyyy-MM-dd")}T${
        times[2]
      }:${times[3]}`;

      addHiddenInputIntoForm("started_at", started_at);
      addHiddenInputIntoForm("ended_at", ended_at);
    }
  }

  function searchText(values) {
    return values.join("、").length > 5
      ? values.join("、").substr(0, 5) + "..."
      : values.join("、");
  }

  // Disable area checkbox
  function disableAreaCheckbox(isDisable) {
    const formContent = document.getElementById("area-content");
    const checkboxs = Array.from(formContent.getElementsByTagName("input"));
    for (let i = 0; i < checkboxs.length; i++) {
      if (checkboxs[i].type === "checkbox") {
        checkboxs[i].nextElementSibling.nextElementSibling.style.color =
          isDisable ? "#a4a4a6" : "#000";
        checkboxs[i].disabled = isDisable;
      }
    }
  }

  function disableAreaSearch() {
    const areaSearchKeyWord = document.getElementById("area-search-keyword");
    // check checkbox
    const formContent = document.getElementById("area-content");
    const inputs = Array.from(formContent.getElementsByTagName("input"));
    inputs.forEach((input) => {
      input.type === "checkbox" &&
        input.addEventListener("click", function (e) {
          if (e.target.checked) {
            areaSearchKeyWord.disabled = true;
          } else {
            areaSearchKeyWord.disabled = false;
          }
        });
    });

    // check input keyword
    areaSearchKeyWord.addEventListener("input", function (e) {
      if (e.target.value.length > 0) {
        disableAreaCheckbox(true);
      } else {
        disableAreaCheckbox(false);
      }
    });

    if (areaSearchKeyWord.value) {
      disableAreaCheckbox(true);
    }
    inputs.forEach((input) => {
      if (input.checked) areaSearchKeyWord.disabled = true;
    });
  }

  function removeAllHiddenInputByNameInform(name) {
    const inputsByName = document.querySelectorAll(`input[name="${name}"`);
    inputsByName.forEach((el) => {
      searchForm.removeChild(el);
    });
  }

  function addHiddenInputIntoForm(name, value) {
    const hiddenInput = `<input type="hidden" name="${name}" value="${value}" />`;
    searchForm.insertAdjacentHTML("afterbegin", hiddenInput);
  }

  // type = area | equipment | time
  function clearAll(type) {
    let clearBtns = document.querySelectorAll(`.clear-button-${type}`);
    clearBtns.forEach((btn) => {
      btn.addEventListener("click", function () {
        const formContent = document.getElementById(`${type}-content`);
        let inputs = Array.from(formContent.getElementsByTagName("input"));
        const selects = Array.from(formContent.getElementsByTagName("select"));
        inputs.forEach((input) => {
          if (input.type === "checkbox") {
            input.checked = false;
          }
          if (input.type === "date") {
            input.value = DEFAULT_DATE;
          } else {
            input.value = "";
          }
        });
        selects.forEach((select, i) => {
          select.value = DEFAULT_TIMES[i];
        });
        if (type === "time") {
          getTimeQuery();
          setTime();
          isClearDate = true;
          started_at = "";
          ended_at = "";
        }
        if (type === "area") {
          document.getElementById("area-search-keyword").disabled = false;
          disableAreaCheckbox(false);
          getAreaQuery();
          setArea();
          areaKeyword = "";
          areas = [];
        }
        if (type === "equipment") {
          getEquipmentQuery();
          setEquipment();
          equipments = [];
        }
      });
    });
  }

  function defaultSearchText(type) {
    switch (type) {
      case "area":
        return "エリアを入力";
      case "equipment":
        return "器具設備";
      case "time":
        return "利用日時";
      default:
        return "";
    }
  }

  function isSearchQueryNone() {
    return (
      started_at === "" &&
      ended_at === "" &&
      areas.length === 0 &&
      equipments.length === 0 &&
      areaKeyword === ""
    );
  }

  searchForm.addEventListener("submit", function (event) {
    if (isSearchQueryNone()) {
      searchForm.action = API;
    } else if (areas.length === 1) {
      searchForm.action = `${API}/${areas[0].uri}/${areas[0].id}`;
    } else {
      searchForm.action = "/search";
    }
  });

  $(".area-search-middle-category-name-wrapper input[type=checkbox]").on("change", (e) => {
    const inputElem = $(e.currentTarget);
    const wrapperElem = inputElem.parents(".area-search-middle-category-name-wrapper").first();
    const checkboxChildren = wrapperElem.next(".area-form-control").find("input[type=checkbox]")
    checkboxChildren.prop("checked", inputElem.prop("checked") === true);
  })

  $(".area-form-control input[type=checkbox]").on("change", (e) => {
    const inputElem = $(e.currentTarget);
    const formControlElem = inputElem.parents(".area-form-control").first()
    const checkboxChildren = formControlElem.find("input[type=checkbox]")
    const checkboxParent = formControlElem.prev(".area-search-middle-category-name-wrapper").find("input[type=checkbox]").first();
    const isAllChecked = checkboxChildren.toArray().every(e => e.checked)
    checkboxParent.prop("checked", isAllChecked)
  })
});
