import $ from "jquery";
import "slick-carousel";

$(document).ready(function () {
  // Slick
  function initSlick() {
    $(".pickup-list")
      .not(".slick-initialized")
      .slick({
        slidesToShow: 3,
        dots: false,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1,
              dots: true,
              slidesToScroll: 1,
              arrows: false,
              variableWidth: true,
            },
          },
        ],
      });
  }
  initSlick();
});
