
$(document).ready( function() {
  const tabHeader = document.querySelector(".area-search-prefectures");
  const tabClass = ".area-search-prefectures-item";

  tabHeader &&
  tabHeader.addEventListener("click", function ({ target }) {
    if (!target.closest(tabClass)) return;
    const id = target.dataset.tab;

    const tab = document.getElementById(id);

    const tabsHeader = document.querySelectorAll(tabClass);
    tabsHeader.forEach((h) => {
      h.classList.remove("active");
    });
    const tabs = document.querySelectorAll(".area-search-tabs-content");
    tabs.forEach((tab) => {
      tab.classList.remove("active");
    });
    target.classList.add("active");
    tab.classList.add("active");
  });

})

